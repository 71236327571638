.recommendations {
  display: block;
  width: 40%;


  .recommendations-block {
    background: #222;
    padding: 30px 20px;
    h3 {
      margin: 0px;
      margin-bottom: 20px;
      color: #FF4D4D;
    }

    + .recommendations-block {
      margin-top: 20px;
    }
    
    p {
      padding-top: 15px;
      padding-bottom: 15px;
      margin: 0px;
      padding-left: 12px;
      padding-right: 12px;
      background: #2f2f2f;
      + p {
        border-top: none;
        margin-top: 16px;
      }
    }
  }
    
  @media (max-width: 767px) { 
    width: 100%;
  }
}


$primary-color: #E63946;
$spinner-size: 40px;
$spinner-border-width: 4px;

.loader {
  display: inline-block;
  width: $spinner-size;
  height: $spinner-size;
  border: $spinner-border-width solid rgba(0, 0, 0, 0.1); // Light background for contrast
  border-radius: 50%;
  border-top: $spinner-border-width solid $primary-color;
  animation: spin 1s linear infinite;

  // Centering the loader
  margin: 0 auto;
}

.loader-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

// Animation for the spinning effect
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.field-of-view {
  padding: 25px 20px;
  background: #222;
  text-align: center;


  width: 60%;

  canvas {
    max-width: 100%;
    margin-top: 20px;
  }

  @media (max-width: 767px) { 
    width: 100%;
    margin-top: 20px;
    padding: 0px;
    background: transparent
  }
}