.header {
 &__logo {
  img {
    height: 79px;
    margin-left: -30px;
    object-fit: cover;
    width: 362px;
  }
 }

  background: #080808;
  padding-top: 10px;
  padding-bottom: 10px;
  
  @media screen and (max-width: 767px) {
    &__container {
      text-align: center;
      img {
        margin-left: auto;
        margin-right: auto;
      }
    }
    
  }
}