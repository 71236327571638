.footer {
  background: #080808;
  padding-top: 20px;
  padding-bottom: 20px;
  position: fixed;
  width: 100%;
  bottom: 0;

  p {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;

    span {
      color: #FF4D4D;
    }
  }
}