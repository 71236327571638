.app-description {

  &__container {
    display: flex;
    column-gap: 16px;
  }

  &__left,
  &__right {
    background: #222;
    padding: 25px;

   * + * {
      margin-top: 20px;
    }
  }
  @media (max-width: 767px) {
    &__container {
      display: block;
    }

    &__right {
      margin-top: 16px;
    }
  }
}

.equipment-form {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }

  &__form-group {
    position: relative;

    label {
      margin-top: 22px;
      position: absolute;
      font-size: 10px;
      left: 14px;
      top: 11px;
      color: #FF4D4D;
    }

    * {
      display: block;
    }
  }


  button {
    padding: 24px 10px;
    border: none;
    font-size: 16px;
    margin: 10px 0;
    width: 100%;
    background: #E63946;
    color: #efefef;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'Quantico', sans-serif;
    margin-top: 17px;
    
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .dropdown-list {
    background: #222;
    position: absolute;
    top: 100px;
    width: 100%;
  
    padding: 20px;

    li {
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer; 
      font-weight: bold;

      &:hover {
        color: #FF4D4D;
      }
      + li {
        border-top: 1px solid #999;
      }
    }
  }

  select,
  input {
    background: #222;
    padding: 14px;
    padding-top: 35px;
    color: #d8d8d8;
    option {
      color: #d8d8d8;
    }
  }

  &__results {
    display: flex;
    margin-top: 40px;
    column-gap: 30px;
    padding-bottom: 100px;
  }

  @media (max-width: 767px) {
    padding-top: 20px;

    &__results {
      display: block;
    }

    &__wrapper {
      grid-template-columns: 1fr;
    }
  }
}